<template>
  <div style="color: #a2a2a2">
    <div style="margin: 10px; padding: 0 0 10px; border-bottom: 1px dashed #ccc; color: #a6a6a6; font-weight: bold;">
      申诉帮助
    </div>
    <div>
      <el-row>
        <img src="/images/accountappeal/step1.jpg"/>
      </el-row>
      <el-row>
        <img src="/images/accountappeal/step2.png"/>
      </el-row>
      <el-row>
        <img src="/images/accountappeal/step3.png"/>
      </el-row>
      <el-row>
        <img src="/images/accountappeal/step4.png"/>
      </el-row>
      <el-row>
        <img src="/images/accountappeal/step5.png"/>
      </el-row>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'accountAppealHelp',
    components: {},
    data () {
      return {}
    },
    methods: {}
  }
</script>

<style scoped>
  img {
    box-shadow: 0 0 5px 0 #ccc;
    margin: 10px 50px;
    display: block;
    width: calc(100% - 100px);
  }
</style>
